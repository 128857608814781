<template>
    <div>
      <InnerDetail>
        <template v-slot:title>
          {{ $route.meta.title }}
        </template>
        <template v-slot:detail>
          <div class="detail-content">
            <div class="sub-title">查学时公众号操作指南</div>
            <p>1.首先扫码关注公众号</p>
            <p>2.进入公众号以后点查学时</p>
            <p>3.输入学员手机号，点发送验证码，然后输入验证码后点击下一步</p>
            <p>4.输入学员身份证号，选择培训所在地，然后选择是学员还是教练，最后选择完成注册</p>
            <p>5.注册成功以后，进入查学时界面，学员需要缴费1元后方可操作查学时和绑定教练员，绑定教练员后，教练也可以查看学员的培训情况</p>
            <p>6.缴费成功后，进入查学时界面，点击第二阶段活着第三阶段，学员可以查看自己的实际练车进度（包括审核通过与未通过以及待审核的学时情况）。</p>
            <p>7.教练关注公众号，不收取任何费用，学员绑定本驾校的教练员，教练员方可查看学员的学时情况。</p>
          </div>
          <div class="detail-img">
            <img src="@/assets/img/product/xinxikeji1.jpg" alt="">
          </div>
        </template>
      </InnerDetail>
    </div>
</template>
<script>
import InnerDetail from "@/views/product/components/InnerDetail.vue";

export default {
    name: 'MyComponent',
  components: {InnerDetail},
}
</script>
